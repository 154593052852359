import { Grid, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CognitoService from './cognitoService';
import ChangePassword from './changepassword'

const Profile = (props: any) => {

    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    useEffect(() => {

        const userid = CognitoService.getUserId();
        if (userid) {
            setIsLoggedIn(true);
        } else {
            // not logged in.
            document.location.href = "/";
        }

    }, [])

    return (
        <React.Fragment>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <ChangePassword></ChangePassword>
                </Box>
            </Grid>
        </React.Fragment>
    );
}

export default Profile