import React, { useState } from 'react';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import poolData from './config';
import { TextField, Button } from '@mui/material';
import ForgotPasswordConfirmation from './forgotPasswordConfirmation';

const ForgetPassword = () => {

    const [form, setForm] = useState<any>(null);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setForm((prevState: any) => ({
            ...prevState,
            [name]: value
        }))
    }

    const sendCode = () => {
        if (!form?.username) {
            alert('Please enter a valid email');
            return;
        }

        //move logic to CognitoService

        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

        let userData = {
            Username: form.username,
            Pool: userPool,
        };

        let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

        cognitoUser?.forgotPassword({
            onSuccess: function (data: any) {
                alert('if your email is registered with us, you should receive an email from us with the code to reset your password. check your email and use the code to reset your password using the form below')
            },
            onFailure: function (err: any) {
                alert(err.message || JSON.stringify(err));
            }
        });
    }

    return (
        <React.Fragment>
            <form autoComplete="off">
                <div>
                    <div>
                        <i>Enter your registered email to reset your password. We will send you confirmation code to reset your password</i>
                    </div>
                    <div>
                        <TextField
                            required
                            placeholder="Email"
                            name="username"
                            onChange={handleChange}
                            id="code">
                        </TextField>
                    </div>
                </div>

                <Button variant="contained" color="primary"
                    onClick={sendCode}>Reset Password
                </Button>
            </form>
            <ForgotPasswordConfirmation username={form?.username}></ForgotPasswordConfirmation>
        </React.Fragment>
    )
}

export default ForgetPassword