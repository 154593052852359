import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import Categories from './categories';

const Home = (props: any) => {

    useEffect(() => {
    }, [])

    return (
        <React.Fragment>
            <Grid container component="main" sx={{ minHeight: '70vh' }}>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Categories></Categories>
                </Box>
            </Grid>
        </React.Fragment>
    )
}

export default Home