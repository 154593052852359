import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from './copyright'
import { Grid, Link } from '@mui/material';

export default function Footer() {

    const footers = [
        {
            group: 'Legal',
            menuItems: [
                {
                    title: 'Terms',
                    linkTo: 'terms',
                },
                {
                    title: 'Compatibility',
                    linkTo: 'compatibility',
                },
            ],
        },
        // {
        //     title: 'Features',
        //     description: [
        //         'Cool stuff',
        //         'Random feature',
        //         'Team feature',
        //         'Developer stuff',
        //         'Another one',
        //     ],
        // },
    ];

    return (
        <React.Fragment>
            <Container
                maxWidth="md"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 8,
                    py: [3, 6],
                }}
            >
                <Grid container spacing={4} justifyContent="space-evenly">
                    {footers.map((footer) => (
                        <Grid item xs={6} sm={3} key={footer.group}>
                            <Typography variant="h6" color="text.primary" gutterBottom>
                                {footer.group}
                            </Typography>
                            <ul>
                                {footer.menuItems.map((item) => (
                                    <li key={item.title}>
                                        <Link href={item.linkTo} variant="subtitle1" color="text.secondary">
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Grid>
                    ))}
                </Grid>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </React.Fragment>
    );
}