import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Items from './items';
import * as repository from '../services/repository'

const Categories = (props: any) => {
    const [categories, setCategories] = useState<any>(null)
    const [categoryId, setCategoryId] = useState<any>(null)
    const [categoryName, setCategoryName] = useState<any>(null)

    useEffect(() => {
        repository.getCategories()
        .then(
            (response: any) => {
                setCategories(response.data)
                setCategoryId(response.data[0].id)
                setCategoryName(response.data[0].name)
            }
        )
        .catch(function (error: any) {
            console.log(error)
        })
    }, [])

    const loadItems = (id: string, name: string) => {
        setCategoryId(id)
        setCategoryName(name)
    }

    let categoriesDiv = categories?.map(function (item: any) {

        return (
            <div key={item.id}>
                <div style={{cursor: 'pointer'}} onClick={() => loadItems(item.id, item.name)}>
                    {item.name}
                </div>
                <div><img height="50" src={item.icon}></img></div>
                <hr />
            </div>
        )
    })

    return (
        <React.Fragment>
            <Grid container>
                <Grid>
                    {categoriesDiv}
                </Grid>
                <hr data-width="1" data-size="500" />
                <Grid>
                    <Items categoryName={categoryName} categoryId={categoryId}></Items>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Categories