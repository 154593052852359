import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Home from './components/home';
import Nav from './components/nav';
import Items from './components/items';
import Profile from './components/auth/profile';
import Footer from './components/footer';
import SignUp from './components/auth/signup';
import SignIn from './components/auth/signin';
import Terms from './components/terms';
import Compatibility from './components/compatibility';

import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import ForgetPassword from './components/auth/forgotpassword';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MyBookmarks from './components/mybookmarks';

function App() {

    const mdTheme = createTheme();

    return (
        <ThemeProvider theme={mdTheme}>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />

            <BrowserRouter>
                <Nav></Nav>
                <Box
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Switch>
                        <Route path="/terms" component={Terms} ></Route>
                        <Route path="/mybookmarks" component={MyBookmarks} ></Route>
                        <Route path="/compatibility" component={Compatibility} ></Route>
                        <Route path="/signin" component={SignIn} ></Route>
                        <Route path="/signup" component={SignUp} ></Route>
                        <Route path="/forgetpassword" component={ForgetPassword} ></Route>
                        <Route path="/profile" component={Profile} ></Route>
                        <Route path="/items/:categoryId" component={Items} ></Route>
                        <Route path="/" component={Home} ></Route>
                    </Switch>
                </Box>
                <Footer></Footer>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
