import { Link, Typography } from '@mui/material';
import React from 'react';

const Copyright = (props: any) => {

    return (
        <React.Fragment>
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {'Copyright © '}
                <Link color="inherit" href="https://speakmyanmar.com/">
                    SpeakMyanmar
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </React.Fragment>
    )
}

export default Copyright