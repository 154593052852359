import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, Paper, Box, Avatar, Typography, TextField, Button, Link, Checkbox } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Unsplash from './unsplash';
import Copyright from '../copyright';
import CognitoService from './cognitoService';

const SignUp = () => {

    const [form, setForm] = useState<any>(null);

    const signup = () => {
        if (!(form && form.firstname && form.lastname && form.email && form.password)) {
            alert('all the fields are required')
            return;
        }

        if(!form.agreeTerms) {
            alert('you must accept Terms to sign up')
        }

        CognitoService.register(form.firstname, form.lastname, form.email, form.password)
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setForm((prevState: any) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleChangeTerms = (e: any) => {
        const { name, checked } = e.target;
        setForm((prevState: any) => ({
            ...prevState,
            [name]: checked
        }))
    }

    return (
        <React.Fragment>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <Unsplash></Unsplash>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign Up
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="firstname"
                                        label="First Name"
                                        autoFocus
                                        autoComplete="first-name"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Last Name"
                                        name="lastname"
                                        autoComplete="last-name"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox name="agreeTerms" value="agreeTerms" color="primary" onChange={handleChangeTerms} />
                                    By clicking Sign Up you agree our <NavLink to={'/terms'}>Terms</NavLink>
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={signup}
                            >
                                Sign Up
                            </Button>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <NavLink to={'/signin'}>
                                        <Link href="#" variant="body2">
                                            Already have an account? Sign in
                                        </Link>
                                    </NavLink>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default SignUp