import { Grid, Box, TextField, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CognitoService from './cognitoService';

const ChangePassword = (props: any) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [form, setForm] = useState<any>(null);

    useEffect(() => {
        const userid = CognitoService.getUserId();
        if (userid) {
            setIsLoggedIn(true);
        } else {
            // not logged in.
            document.location.href = "/";
        }
    }, [])

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setForm((prevState: any) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleSubmit = () => {
        const oldpassword = form.oldpassword;
        const newpassword = form.newpassword;
        const confirmpassword = form.confirmpassword;

        if (!oldpassword) {
            alert('old password is required');
        } else if (!newpassword) {
            alert('new password is required');
        } else if (!confirmpassword) {
            alert('please confirm your new password');
        } else if (newpassword !== confirmpassword) {
            alert('password not match');
        } else {
            CognitoService.changePassword(oldpassword, newpassword)
        }
    }

    return (
        <React.Fragment>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <h1>Change Password</h1>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Old Password"
                        name="oldpassword"
                        autoComplete="password"
                        autoFocus
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="newpassword"
                        label="New Password"
                        type="password"
                        autoComplete="new-password"
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmpassword"
                        label="Confirm Password"
                        type="password"
                        autoComplete="new-password"
                        onChange={handleChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleSubmit}
                    >
                        Change Password
                    </Button>
                </Box>
            </Grid>
        </React.Fragment>
    );
}

export default ChangePassword