import React, { useEffect } from 'react';

const MyBookmarks = (props: any) => {

    useEffect(() => {
    }, [])

    return (
        <React.Fragment>
            You have no bookmarks
        </React.Fragment>
    )
}

export default MyBookmarks