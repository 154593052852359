import { Grid, Box } from '@mui/material';
import React, { useEffect } from 'react';

const Terms = (props: any) => {

    useEffect(() => {
    }, [])

    const siteName = 'speakmyanmar.com';

    return (
        <React.Fragment>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <h1>Terms</h1>

                    <div>
                        <h2>Terms and Conditions</h2>
                        <p>
                            Welcome to our website. If you continue to browse and use this website,
                            you are agreeing to comply with and be bound by the following terms and conditions of use,
                            which together with our privacy policy govern {siteName}'s relationship with you in relation to this website.
                            If you disagree with any part of these terms and conditions, please do not use our website.
                        </p>

                        <h3>Cookies policy</h3>
                        <p>
                            This site uses cookies and other storage technologies to deliver, maintain and improve our services.
                            These cookies are required for the functionality of the site and you must agree to continue using this site.
                            Those cookies may include functional cookies such as login registration or analytical coockies to improve our functionality.
                        </p>
                        <h3>Privacy policy</h3>
                        <p>
                            We store neccessary user data such as email address to deliver the functionality of the site such as user login.
                            We do our best to protect your data. These data may be shared within Myanmarlyrics.net family.
                            We do not sell your data to third party.
                        </p>
                        <h3>Limitation of liability</h3>
                        <p>We do our best effort to secure our site and to protect your data. In unfortunate case of data breach has occoured,
                            because of hackers or malicious third party, we (Myanmarlyrics.net or its sponsors or its partners) will
                            not take the responsiblity for any consequences.
                        </p>
                    </div>
                </Box>
            </Grid>
        </React.Fragment>
    )
}

export default Terms