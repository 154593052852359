import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import poolData from './config';

class CognitoService
{
    static login(email: string, password: string) {
        let authenticationData = {
            Username: email,
            Password: password,
        };
        let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
            authenticationData
        );
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let userData = {
            Username: email,
            Pool: userPool,
        };
        let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                document.location.href = "/";
            },

            onFailure: function (err) {
                if (err.code === "UserNotConfirmedException") {
                    alert('Please confirm your email to login. please check your spam or junk mail folder.');
                } else {
                    alert(err.message || JSON.stringify(err));
                }
            },
        });
    }

    static register(firstname: string, lastname: string, email: string, password: string) {
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        const attributeList = [];
        const dataEmail = {
            Name: 'email',
            Value: email
        }

        const dataFirstname = {
            Name: 'custom:firstname',
            Value: firstname
        }
        const dataLastname = {
            Name: 'custom:lastname',
            Value: lastname
        }

        const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);
        attributeList.push(attributeEmail);

        const attributeFirstname = new AmazonCognitoIdentity.CognitoUserAttribute(dataFirstname);
        attributeList.push(attributeFirstname);

        const attributeLastname = new AmazonCognitoIdentity.CognitoUserAttribute(dataLastname);
        attributeList.push(attributeLastname);

        userPool.signUp(
            email, password, attributeList, attributeList,
            function (err: any, result: any) {
            if (err) {
                alert(err.message);
            } else {
                const message = `you have successfully registered. You need to confirm your email before you can login.`
                alert(message)
                document.location.href = "/signin";
            }
        });
    }

    static getUserId() {
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();
        return cognitoUser? cognitoUser?.getUsername(): '';
    }

    static getUser() {
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();
        return cognitoUser;
    }

    static changePassword(oldpassword: string, newpassword: string) {
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();

        cognitoUser?.getSession(function (err: any, session: any) {
            if (err) {
                alert(err.message || JSON.stringify(err));
                return;
            }
            cognitoUser?.changePassword(oldpassword, newpassword, function (err, result) {
                if (err) {
                    console.log(err);
                    alert(err.message || JSON.stringify(err));
                } else {
                    console.log('call result: ' + result);
                    alert('password changed');
                }
            });
        });
    }

}

export default CognitoService;