import { Container, Grid, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import * as repository from '../services/repository'

const Items = (props: any) => {
    const [items, setItems] = useState<any>(null)
    const categoryId = props.categoryId
    const categoryName = props.categoryName

    useEffect(() => {
        repository.getItemsByCategoryId(categoryId)
        .then(function (response: any) {
            setItems(response.data)
        })
        .catch(function (error: any) {
            console.log(error)
        })
    }, [props.categoryId])

    const toggleFavourite = () => {
        console.log('toggleFavourite')
        //TODO: create end point to toggle favourite
    }

    let itemsDiv = items?.map(function (item: any) {
        return (
            <Paper
                sx={{
                    p: 2,
                    m: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
                key={item.id}
            >
                <div>
                    <Grid container justifyContent="flex-end">
                        <Grid item xs>
                            {item.english}
                        </Grid>
                        <Grid item>
                            {!item.isFavourited && (<BookmarkBorderIcon onClick={toggleFavourite}></BookmarkBorderIcon>)}
                            {item.isFavourited && (<BookmarkIcon onClick={toggleFavourite}></BookmarkIcon>)}
                        </Grid>
                    </Grid>
                    <div>{item.myanmar}</div>
                    <div>{item.pronunciation}</div>
                    <audio controls>
                        <source src={item.audio} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>

                </div>
            </Paper>
        )
    })

    return (
        <React.Fragment>
            <Container>
                <h1>{categoryName}</h1>
                {items && itemsDiv}
                {!items || items.length < 1 && (<>no items found</>)}
            </Container>
        </React.Fragment>
    )
}

export default Items