
import React, { useEffect, useState } from 'react';
import { useHistory, NavLink } from "react-router-dom";
import { AppBar, Button, IconButton, Menu, MenuItem, Typography, Toolbar } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import poolData from './auth/config';

const Nav = (props: any) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    const history = useHistory();

    useEffect(() => {
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession(function (err: any, session: any) {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                    return;
                }

                setIsLoggedIn(session.isValid())
            })
        }
    }, [])

    const handleMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const profileMenuHandler = () => {
        setAnchorEl(null);
        history.push("/profile")
    };

    const myBookmarksMenuHandler = () => {
        setAnchorEl(null);
        history.push("/mybookmarks")
    }

    const signOut = () => {
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();
        cognitoUser?.signOut();

        document.location.href = "/";
    }

    const Guest = () => {
        return (
            <React.Fragment>
                <NavLink
                    to={'/signin'}
                >
                    <Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                        Login
                    </Button>
                </NavLink>
                <NavLink
                    to={'/signup'}
                >
                    <Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                        Register
                    </Button>
                </NavLink>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
            >
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        <NavLink
                            to={'/'}
                        >
                            Speak Myanmar
                        </NavLink>
                    </Typography>
                    {/* <nav>
                        <NavLink
                            to={'/'}
                        >
                            <Link
                                variant="button"
                                color="text.primary"
                                sx={{ my: 1, mx: 1.5 }}
                            >
                                Home
                            </Link>
                        </NavLink>
                    </nav> */}
                    {!isLoggedIn && (
                        <Guest />
                    )}

                    {isLoggedIn && (
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={profileMenuHandler}>Change Password</MenuItem>
                                <MenuItem onClick={myBookmarksMenuHandler}>My Bookmarks</MenuItem>
                                <MenuItem onClick={signOut}>Sign Out</MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </React.Fragment>
    )
}

export default Nav