import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import poolData from './config';

const ForgotPasswordConfirmation = (props: any) => {

    const [form, setForm] = useState<any>(null);

    let handleChange = (e: any) => {
        const { name, value } = e.target;
        setForm((prevState: any) => ({
            ...prevState,
            [name]: value
        }))
    }

    const confirm = () => {

        if (!(form.code && form.newpassword)) {
            alert('missing code or password');
            return;
        }

        //move logic to CognitoService
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

        const userData = {
            Username: form.username,
            Pool: userPool,
        };

        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

        cognitoUser.confirmPassword(form.code, form.newpassword, {
            onSuccess() {
                alert('password confirmed! go back to Login page to continue')
            },
            onFailure(err) {
                alert(err.message);
            },
        });
    }

    return (
        <form autoComplete="off">
            <div>
                <i>Once you received the code, use the form below to reset your password</i>
            </div>
            <div>
                <TextField
                    required
                    name="code"
                    placeholder="Code"
                    onChange={handleChange}
                    id="code">
                </TextField>
            </div>
            <div className="form-group">
                <TextField
                    required
                    id="new_password"
                    name="newpassword"
                    placeholder="New Password"
                    onChange={handleChange}
                    type="password">
                </TextField>
                <div><i>password must be at least 8 characters long and must include number and character</i></div>
            </div>

            <Button variant="contained" color="primary"
                onClick={confirm}>
                Send
            </Button>
        </form>
    )
}

export default ForgotPasswordConfirmation